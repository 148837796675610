<template>
  <div>
    <Header />

    <!--Beneficios-->
    <div class="DivFull_LeftCenter DivPortaBtnVoltaBeneficios">
      <div class="DivBtnVoltaBeneficios" id="DivBtnVoltaBeneficios">
        <a
          @click="Show_Info()"
          href="javascript:void(0)"
          class="BtnVoltaBeneficios"
          id="BtnVoltaBeneficios"
        >
          <img src="./svg/ShowBeneficios.svg" class="" alt="CLOSE" />
        </a>
      </div>
    </div>

    <div class="DivFull_LeftCenter Beneficios" id="Beneficios">
      <div class="container-fluid">
        <div class="col-md-6">
          <div class="rowBlock">
            <h1 class="TituloCor3" id="TituloVisaoSimples">
              Lentes<br />
              Polarizadas
            </h1>

            <a
              @click="Hide_Info()"
              href="javascript:void(0)"
              class="BtnFechaBeneficios top10"
              id="BtnFechaBeneficios"
            >
              <img
                src="./svg/CloseBeneficios.svg"
                class="IconeClose"
                alt="CLOSE"
              />
            </a>
          </div>

          <div class="DivEscolhas">
            <div class="row top10">
              <a
                @click="Open_Modal()"
                href="javascript:void(0)"
                class="ShowBeneficios"
                id="AbreModalBeneficios"
              >
                <img src="./svg/OpenCollapse.svg" class="" alt="Saiba mais" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Beneficios-->

    <!--Modal Beneficios-->
    <div class="ModalBeneficios" id="ModalBeneficios">
      <div class="HeaderModal">
        <h2 class="TituloCor1">Lentes Polarizadas</h2>
      </div>

      <a
        @click="Close_Modal()"
        href="javascript:void(0)"
        class="BtnCloseModal"
        id="FechaModalBeneficios"
      >
        <img src="./svg/CloseModal.svg" alt="Fechar" />
      </a>

      <div class="container ContainerModal">
        <div class="col-12 NoPadding">
          <div class="DivModalBeneficios" id="DivModalBeneficios">
            <div class="owl-carousel owl-theme owl-beneficios">
              <div class="item">
                <div class="row">
                  <img
                    src="./images/Polarizadas/Banner_Polarizadas_1.png"
                    class="img-fluid"
                  />
                </div>

                <div class="row TextPadding top30">
                  <p class="White Opacidade6 TextoSlides">
                    As lentes polarizadas bloqueiam o brilho horizontal que
                    refletem em superfícies e tornam-se ofuscante,
                    proporcionando assim um melhor contraste das cores.<br />
                    São ideais para pessoas com estilo de vida dinâmico e com
                    exposição direta ao sol, seja por hobbie ou esporte ao ar
                    livre, na praia ou na piscina do clube ou que dirigem em
                    horário de sol intenso.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Modal Beneficios-->

    <div class="PolarizadaEscolha">
      <a
        @click="OpenLens('polarized-lens/1')"
        class="BtnPolarizada Polarizada_BtnActive"
        id="Show_Dirigindo"
      >
        <img
          src="./images/Polarizadas/Polarizada_Dirigindo.jpg"
          class="IconePolarizada BtnDirigindo"
          alt="Piscina"
        />
      </a>

      <a
        @click="OpenLens('polarized-lens/2')"
        class="BtnPolarizada"
        id="Show_Piscina"
      >
        <img
          src="./images/Polarizadas/Polarizada_Piscina.jpg"
          class="IconePolarizada BtnPiscina"
          alt="Piscina"
        />
      </a>
    </div>

    <Split style="height: 100vh; display: flex" @onDrag="onDrag">
      <SplitArea>
        <div class="split a" style="width: 100"></div>
      </SplitArea>
      <SplitArea>
        <div class="split b" style="width: 100"></div>
      </SplitArea>
    </Split>
  </div>
</template>

<script>
import Header from '@/views/menu/components/header.vue'
import VueSplit from 'vue-split-panel'

import {
  InicialSet,
  DraggableButton,
} from '@/views/menu/js/new/DraggableButton_Scirpt'
import {
  Hide_Info,
  Show_Info,
  Open_Modal,
  Close_Modal,
} from '@/views/menu/js/new/Scripts'

export default {
  methods: {
    DraggableButton,
    Hide_Info,
    Show_Info,
    Open_Modal,
    Close_Modal,

    OpenLens(route) {
      this.$router.push(`/lens/${route}`)
    },
  },
  components: {
    Header,
  },
  mounted() {
    InicialSet()
  },
}
</script>

<style>
@import './css/Style.css';
@import './css/MyStyle.css';
@import './css/MyLogin.css';
@import './css/MyColors.css';
@import './css/MyMenu.css';
@import './css/MyMenuInterno.css';
@import './css/plugins/animate.css';
@import './css/plugins/hamburger.css';
/* @import './css/plugins/owl.carousel.min.css'; */
@import './css/plugins/owl.theme.default.min.css';

@import './css/Polarizadas_Style.css';

@import './css/plugins/images-compare.css';

@import './css/plugins/Compare_Polarizada_Dirigindo.css';

.no-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.split {
  width: 101%;
  height: 100vh;
}
.a {
  background: url('./images/Polarizadas/Polarizada_Dirigindo_ComBrilho.jpg')
    center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.b {
  background: url('./images/Polarizadas/Polarizada_Dirigindo_SemBrilho.jpg')
    center center no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.gutter {
  background-color: red !important;
  width: 50px !important;
}
.gutter.gutter-horizontal {
  cursor: e-resize;

  left: 50% !important;
  margin-left: -25px !important;
  margin-right: -25px !important;
  margin-top: -25px !important;
  z-index: 5 !important;
  cursor: pointer !important;
  background: url('./svg/Setas.svg') center center no-repeat !important;
  background-size: 50px !important;
}
</style>
